/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
// form
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, CardHeader, Container, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField, FormProvider, RHFUploadSingleFile, RHFSwitch } from '../../../../components/hook-form';
import CustomSelect from '../../../../components/hook-form/CustomSelectInput';
import { adminApi } from '../../../../utils/axios';
import { ADMIN_OFFERS, RETAIL_USERS } from '../../../../utils/apiUrl';
import RHFPhoneInput from '../../../../components/hook-form/RHFPhoneInput';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../hooks/useSettings';
import { PATH_ACCOUNT, PATH_CONFIGURATION, PATH_MERCHANTS } from '../../../../routes/paths';
import RHFDateTimePicker from '../../../../components/hook-form/RHFDateTimePicker';
import TextEditor from '../../../../components/textEditor';
import MultiSelectInput from '../../../../components/hook-form/MultiSelectInput';
import { getFormData } from '../../../../utils/common';

const OFFER_CATEGORY = [
  {
    name: 'Referral',
    value: 'referral',
  },
  {
    name: 'Signup',
    value: 'signup',
  },
  {
    name: 'Daily Cashback',
    value: 'daily_transaction',
  },
];

const BENEFICIARIES = [
  {
    name: 'User',
    value: 'user',
  },
  {
    name: 'Recipient',
    value: 'recipient',
  },
  {
    name: 'Both',
    value: 'both',
  },
];

const OFFER_TYPE = [
  {
    name: 'Cashback',
    value: 'cashback',
  },
  {
    name: 'Discount',
    value: 'discount',
  },
  {
    name: 'Bonus',
    value: 'bonus',
  },
];

const CASHBACK_OPTIONS = [
  {
    name: 'Percentage',
    value: 'percentage',
  },
  {
    name: 'Fixed Amount',
    value: 'fixed_amount',
  },
];

export default function OfferCreateEdit() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const offerState = location.state;

  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();

  const offerCreateEditSchema = Yup.object().shape({
    title: Yup.string().required('This is required'),
    offer_type: Yup.string().required('This is required'),
    offer_category: Yup.string().required('This is required'),
    amount: Yup.string().when('offer_category', {
      is: (value) => value === 'signup' || value === 'daily_transaction',
      then: () => Yup.string().required('This is a required field'),
    }),
    amount_for_referee: Yup.string().when('offer_category', {
      is: 'referral',
      then: () => Yup.string().required('This is a required field'),
    }),
    amount_for_refered: Yup.string().when('offer_category', {
      is: 'referral',
      then: () => Yup.string().required('This is a required field'),
    }),
  });

  const defaultValues = useMemo(
    () => ({
      title: offerState?.currentOffer?.title || '',
      description: offerState?.currentOffer?.description || '',
      term_condition: offerState?.currentOffer?.term_condition || '',
      image: offerState?.currentOffer?.image || null,
      is_active: offerState?.currentOffer?.is_active || false,
      is_percentage: offerState?.currentOffer?.is_percentage || false,
      expiry_date_time: offerState?.currentOffer?.expiry_date_time || null,
      offer_category: offerState?.currentOffer?.offer_category || '',
      amount_for_referee: offerState?.currentOffer?.amount_for_referee || '',
      amount_for_refered: offerState?.currentOffer?.amount_for_refered || '',
      amount: offerState?.currentOffer?.amount || '',
      max_amount: offerState?.currentOffer?.max_amount || '',
      offer_type: offerState?.currentOffer?.offer_type || '',
      min_deposit_for_signup_bonus: offerState?.currentOffer?.min_deposit_for_signup_bonus || '',
      min_txn_amount_for_daily_txn_bonus: offerState?.currentOffer?.min_txn_amount_for_daily_txn_bonus || '',
      time_in_hours_for_crediting_bonus: offerState?.currentOffer?.time_in_hours_for_crediting_bonus || '0',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(offerCreateEditSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods;

  const handleClear = (name) => {
    setValue(name, '');
  };

  const values = watch();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (offerState?.isEdit) {
        const requestData = {
          ...data,
        };
        const updatedDataObj = {};
        const keys = Object.keys(defaultValues);
        keys.forEach((key) => {
          if (defaultValues[key] !== requestData[key]) {
            updatedDataObj[key] = requestData[key];
          }
        });
        adminApi
          .patch(`${ADMIN_OFFERS}${offerState?.currentOffer?.id}/`, getFormData(updatedDataObj))
          .then(() => {
            setIsLoading(false);
            enqueueSnackbar(!offerState?.isEdit ? 'Create success!' : 'Update success!');
            navigate(PATH_CONFIGURATION.offers.root);
          })
          .catch((error) => {
            setIsLoading(false);
            if (typeof error?.response?.data === 'object' && error?.response?.status === 400) {
              setIsLoading(false);
              enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            } else {
              setIsLoading(false);
              enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
          });
      } else {
        const requestData = {
          ...data,
        };
        // console.log('data offer', requestData);
        adminApi
          .post(`${ADMIN_OFFERS}`, getFormData(requestData))
          .then(() => {
            reset();
            setIsLoading(false);
            enqueueSnackbar(!offerState?.isEdit ? 'Create success!' : 'Update success!');
            navigate(PATH_CONFIGURATION.offers.root);
          })
          .catch((error) => {
            setIsLoading(false);
            if (typeof error?.response?.data === 'object' && error?.response?.status === 400) {
              setIsLoading(false);
              enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            } else {
              setIsLoading(false);
              enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveContinueEditHandler = async (data) => {
    const isValid = await trigger();
    if (isValid) {
      try {
        setIsSaveLoading(true);
        if (offerState?.isEdit) {
          const requestData = {
            ...data,
          };
          const updatedDataObj = {};
          const keys = Object.keys(defaultValues);
          keys.forEach((key) => {
            if (defaultValues[key] !== requestData[key]) {
              updatedDataObj[key] = requestData[key];
            }
          });
          // console.log('updated data', updatedDataObj);
          adminApi
            .patch(`${ADMIN_OFFERS}${offerState?.currentOffer?.id}/`, getFormData(updatedDataObj))
            .then(() => {
              setIsSaveLoading(false);
              enqueueSnackbar(!offerState?.isEdit ? 'Create success!' : 'Update success!');
            })
            .catch((error) => {
              setIsSaveLoading(false);
              if (typeof error?.response?.data === 'object' && error?.response?.status === 400) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
              } else {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
              }
            });
        } else {
          const requestData = {
            ...data,
          };
          adminApi
            .post(`${ADMIN_OFFERS}`, getFormData(requestData))
            .then(() => {
              setIsSaveLoading(false);
              enqueueSnackbar(!offerState?.isEdit ? 'Create success!' : 'Update success!');
            })
            .catch((error) => {
              setIsSaveLoading(false);
              if (typeof error?.response?.data === 'object' && error?.response?.status === 400) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
              } else {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
              }
            });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log('Validation errors:', errors);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleDateChange = (momentDate) => {
    if (momentDate && momentDate.isValid()) {
      // Convert Moment date to ISO 8601 format
      const isoFormat = momentDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      setValue('expiry_date_time', isoFormat);
    }
  };

  const resetFormHandler = () => {
    reset({
      title: '',
      description: '',
      term_condition: '',
      image: null,
      is_active: false,
      is_percentage: false,
      expiry_date_time: null,
      offer_category: '',
      amount_for_referee: '',
      amount_for_refered: '',
      amount: '',
      min_txn_amount_for_daily_txn_bonus: '',
      max_amount: '',
      min_deposit_for_signup_bonus: '',
      offer_type: '',
      time_in_hours_for_crediting_bonus: '0',
    });
  };

  return (
    <Page title={offerState?.isEdit ? 'Edit Offer' : 'Add New Offer'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={offerState?.isEdit ? 'Edit Offer' : 'Add New Offer'}
          links={[
            { name: 'Store' },
            // { name: 'User', href: PATH_DASHBOARD.account.users },
            { name: offerState?.isEdit ? 'Edit' : 'Add' },
          ]}
        />
        <Card sx={{ maxWidth: '80%', margin: '0 auto' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ p: 3 }}>
              <RHFTextField name="title" label="Title" helperText="Title for the Offer" />
            </Box>
            <Box sx={{ p: 3 }}>
              <RHFTextField
                name="description"
                label="Description"
                multiline
                rows={2}
                maxRows={8}
                helperText="Description of the Offer"
              />
            </Box>
            <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography variant="overline" sx={{ color: 'text.primary', fontSize: '16px' }}>
                Terms and Condition
              </Typography>
              <TextEditor setFieldValue={(val) => setValue('term_condition', val)} value={values.term_condition} />
            </Box>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                p: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFUploadSingleFile
                title="Image"
                name="image"
                accept="image/*"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText="Image of offer banner. Will be displayed in app"
              />
            </Box>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                p: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFSwitch name={'is_active'} label={'is Active'} sx={{ m: 0 }} />
              <RHFDateTimePicker
                inputFormat="DD/MM/YYYY hh:mm A"
                name={`expiry_date_time`}
                label="Expiry Date & Time"
                onChange={handleDateChange}
              />
              <RHFSwitch
                name={'is_percentage'}
                label={'is Percentage'}
                sx={{ m: 0, pointerEvents: offerState?.isEdit ? 'none' : 'visible' }}
              />
              <RHFTextField
                name="max_amount"
                label="Max Amount"
                disabled={offerState?.isEdit}
                helperText="This field is used to set the maximum amount that can be used in offer"
              />
              <RHFTextField
                name="time_in_hours_for_crediting_bonus"
                label="Time in Hours For Crediting Bonus"
                helperText="0 for instant"
              />
            </Box>
            <Box sx={{ p: 3 }}>
              <Typography variant="overline" sx={{ color: 'text.primary', fontSize: '18px' }}>
                Conditions
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 3,
                  columnGap: 2,
                  pt: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <CustomSelect
                  name="offer_type"
                  label="Offer Type"
                  placeholder="Offer Type"
                  isClear={handleClear}
                  helperText="This field is used to select offer type eg. cashback, bonus"
                >
                  {OFFER_TYPE?.map((option) => (
                    <MenuItem
                      // selected={value === option.value}
                      selected
                      key={option?.name}
                      value={option?.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {option?.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
                <CustomSelect
                  name="offer_category"
                  label="Offer Category"
                  placeholder="Offer Category"
                  isClear={handleClear}
                >
                  {OFFER_CATEGORY?.map((option) => (
                    <MenuItem
                      // selected={value === option.value}
                      selected
                      key={option?.name}
                      value={option?.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {option?.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
                {values?.offer_category === 'referral' && (
                  <RHFTextField
                    name="amount_for_referee"
                    label="Cashback Amount to referee"
                    disabled={offerState?.isEdit}
                  />
                )}
                {values?.offer_category === 'referral' && (
                  <RHFTextField
                    name="amount_for_refered"
                    label="Cashback Amount to refered"
                    disabled={offerState?.isEdit}
                  />
                )}
                {(values?.offer_category === 'signup' || values?.offer_category === 'daily_transaction') && (
                  <RHFTextField name="amount" label="Amount" disabled={offerState?.isEdit} />
                )}
                {values?.offer_category === 'signup' && (
                  <RHFTextField name="min_deposit_for_signup_bonus" label="Min Deposit for Signup Bonus" />
                )}
                {values?.offer_category === 'daily_transaction' && (
                  <RHFTextField
                    name="min_txn_amount_for_daily_txn_bonus"
                    label="Min Transaction Amount For Daily Transaction Bonus"
                  />
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, p: 3 }}>
              <Box>
                <LoadingButton type="button" variant="contained" onClick={() => resetFormHandler()}>
                  Reset
                </LoadingButton>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <LoadingButton
                  type="button"
                  variant="contained"
                  onClick={() => saveContinueEditHandler(values)}
                  loading={isSaveLoading}
                >
                  {offerState?.isEdit ? 'Update & Continue Edit' : 'Save & Continue Edit'}
                </LoadingButton>
                <LoadingButton type="submit" variant="contained" loading={isLoading}>
                  {offerState?.isEdit ? 'Update & Apply' : 'Save & Apply'}
                </LoadingButton>
              </Box>
            </Box>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}

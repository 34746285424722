import React, { useState, useEffect } from 'react';
import { Box, Card, FormControlLabel, Switch, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSkeleton } from '../../../../../components/table';
import useTable, { emptyRows } from '../../../../../hooks/useTable';
import RetailTransactionTableRow from './retailTransactionTableRow';
import { adminApi } from '../../../../../utils/axios';
import { ADMIN_RETAIL_REPORT } from '../../../../../utils/apiUrl';
import RetailTransactionHeaders from './retailTransactionHeaders';
import RetailTransactionsFilter from './retailTransactionsFilter';
import { fCustomDate } from '../../../../../utils/formatTime';

const TABLE_HEAD = [
  { id: 'created_date', label: 'Date', align: 'left' },
  { id: 'reference_number', label: 'Tran ID', align: 'left' },
  { id: 'payment_mode', label: 'Payment Gateway', align: 'left' },
  { id: 'sender', label: 'From', align: 'left' },
  { id: 'receiver', label: 'To', align: 'left' },
  { id: 'transaction_type', label: 'Transaction Type', align: 'left' },
  { id: 'send_amount', label: 'Transaction Value', align: 'left' },
  { id: 'other_fee', label: 'Transaction Fee', align: 'left' },
  { id: 'cashback', label: 'Cashback', align: 'left' },
  { id: 'discount', label: 'Discounts', align: 'left' },
  { id: 'stb_fee', label: 'STB Transaction Fee', align: 'left' },
  { id: 'receipt_amount', label: 'Merchant/Bill Provider Receipt', align: 'left' },
  { id: 'stb_earning', label: 'STB Earning', align: 'left' },
  { id: 'stb_expense', label: 'STB Loss', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
];

const RetailTransactionsData = () => {
  // Getting Current Date
  const currentDate = new Date();

  // Getting Date which is 30 Days back from current date
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = useTable();

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState({ count: 0, data: [] });
  const [filterParameters, setFilterParameters] = useState({
    search: '',
    transaction_type: '',
    status: 'processed',
    from_date: fCustomDate(new Date(thirtyDaysAgo)),
    to_date: fCustomDate(new Date(currentDate)),
  });
  const [isProfitLoss, setIsProfitLoss] = useState(0);
  const [totalData, setTotalData] = useState(null);
  const [netEarning, setNetEarning] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getRetailTransaction();
  }, [rowsPerPage, page, filterParameters, order, orderBy]);

  useEffect(() => {
    if (totalData) {
      const totalEarning = totalData.stb_fee - (totalData.discount + totalData.cashback);
      setIsProfitLoss(Math.sign(totalEarning));
      setNetEarning(totalEarning);
    }
  }, [totalData, setIsProfitLoss, setNetEarning]);

  // Getting Retail Transaction Data
  const getRetailTransaction = async () => {
    // console.log('filter parameters', filterParameters);
    setIsLoading(true);
    try {
      const response = await adminApi.get(ADMIN_RETAIL_REPORT, {
        params: {
          is_merchant: false,
          offset: rowsPerPage * page,
          limit: rowsPerPage,
          ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
          ...filterParameters,
        },
      });
      console.log(response, 'response');
      setIsLoading(false);
      setTableData(response?.data);
      setTotalData({ ...response?.data.totals, total_transactions: response?.data.count });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error?.message, { variant: 'error' });
      setTableData(error);
    }
  };

  // If there is no data
  const isNotFound = tableData?.results?.length === 0;

  // Check Dense Height
  const denseHeight = dense ? 52 : 72;

  console.log('current page', page);

  return (
    <Card sx={{ margin: '40px 0px' }}>
      {/* Retail Report Filter */}
      <RetailTransactionsFilter
        filterParameters={filterParameters}
        onFilterChange={(val) => {
          setFilterParameters(val);
          setPage(0);
        }}
      />

      {/* Retail Report Headers */}
      {totalData && (
        <RetailTransactionHeaders netEarning={netEarning} totalData={totalData} isProfitLoss={isProfitLoss} />
      )}

      {/* Retail Report Data Table */}
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Scrollbar>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.count}
              onSort={onSort}
            />
            {isLoading ? (
              <>
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
              </>
            ) : (
              <TableBody>
                {tableData?.results?.map((row) => (
                  <RetailTransactionTableRow key={row.id} row={row} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.count)} />
                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            )}
          </Table>
        </Scrollbar>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={tableData.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />

        <FormControlLabel
          control={<Switch checked={dense} onChange={onChangeDense} />}
          label="Dense"
          sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
        />
      </Box>
    </Card>
  );
};

export default RetailTransactionsData;

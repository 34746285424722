import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import MasterTransactions from '../pages/dashboard/Reporting/masterTransactions/masterTransactions';
import P2pTransactions from '../pages/dashboard/Reporting/p2pTransactions/p2pTransactions';
import P2bTransactions from '../pages/dashboard/Reporting/p2bTransactions/p2bTransactions';
import BillPayments from '../pages/dashboard/Reporting/billPayments/billPayments';
import WithdrawlReport from '../pages/dashboard/Reporting/withdrawlReport/withdrawlReport';
import Deposits from '../pages/dashboard/Reporting/deposits/deposits';
import RetailKycList from '../pages/dashboard/users/retailKYC/retailKycList';
import MerchantKybTableList from '../pages/dashboard/users/merchantKYB/merchantKybTableList';
import MerchantUserCreateStore from '../pages/dashboard/merchants/MerchantsList/merchantUserCreateStore/merchantUserCreateStore';
import MerchantUserCreateSystemUser from '../pages/dashboard/merchants/MerchantsList/merchantUserCreateSystemUser/merchantUserCreateSystemUser';
import OfferCreateEdit from '../pages/dashboard/configurations/offers/offerCreateEdit';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { pathname } = useLocation();
  console.log(pathname, window.location.href);

  return useRoutes([
    {
      path: 'stb',
      children: [
        {
          path: 'change-password',
          element: <SetupPassword />,
        },
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'set-up-two-factor-authentication', element: <TwoFactorAuthentication /> },
        { path: 'verify-two-factor-authentication', element: <VerifyTwoFactorAuthentication /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true }],
    },
    {
      path: 'account',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <RetailUsers />, index: true },
        { path: 'retail-users', element: <RetailUsers /> },
        { path: 'retail-users/profile/:id/:tabname', element: <UserAccount /> },
        { path: 'retail-users/new', element: <UserCreate /> },

        {
          path: 'merchant-users',
          children: [
            { element: <MerchantUsers />, index: true },
            { path: 'profile/:id/:tabname', element: <UserAccount /> },
            { path: 'new', element: <MerchantUserCreate /> },
          ],
        },
        { path: 'password-change-table', element: <PasswordChangeTable /> },
        {
          path: 'retail-kyc',
          children: [
            { element: <RetailKycList />, index: true },
            { path: 'edit/:id', element: <RetailKYCCreate /> },
            { path: 'new', element: <RetailKYCCreate /> },
          ],
        },
        {
          path: 'merchant-kyb',
          children: [
            { element: <MerchantKybTableList />, index: true },
            { path: 'edit/:id', element: <MerchantKYBCreate /> },
            { path: 'new', element: <MerchantKYBCreate /> },
          ],
        },
        { path: 'profile', element: <ManageProfile /> },
        { path: 'new', element: <UserCreate /> },
        { path: 'account', element: <UserAccount /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    {
      path: 'transactions',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'bitgo', element: <BitgoTransactions /> },
        { path: 'bitgo/transaction', element: <BitgoTransaction /> },
        { path: 'apaylo/deposit', element: <DepositTransaction /> },
        { path: 'apaylo/withdraw', element: <WithdrawTransaction /> },
        { path: 'wallet', element: <WalletTransactions /> },
        { path: 'wallet/transaction', element: <WalletTransaction /> },
        { path: 'wallet/transaction/retail-user', element: <RetailWalletTransaction /> },
        { path: 'wallet/transaction/merchant-user', element: <MerchantWalletTransaction /> },
        { path: 'wallet/transaction/:id/edit', element: <WalletTransactionCreate /> },
        { path: 'funding-queue', element: <FundingQueue /> },
        { path: 'funding-queue/new', element: <FundingQueueCreate /> },
        { path: 'funding-queue/:id/edit', element: <FundingQueueCreate /> },
        { path: 'eft-accounts', element: <EftAccountsPage /> },
        { path: 'deposit/retail-user', element: <RetailDeposit /> },
        { path: 'deposit/:id/edit', element: <DepositEdit /> },
        { path: 'withdraw/retail-user', element: <RetailWithdraw /> },
        { path: 'withdraw/merchant-user', element: <MerchantWithdraw /> },
        { path: 'withdraw/:id/edit', element: <WithdrawEdit /> },
      ],
    },
    {
      path: 'merchants',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'business-categories', element: <BusinessCategory /> },
        { path: 'business-categories/new', element: <BusinessCategoryCreate /> },
        { path: 'business-categories/:id/edit', element: <BusinessCategoryCreate /> },
        { path: 'business-type', element: <BusinessType /> },
        { path: 'business-type/new', element: <BusinessTypeCreate /> },
        { path: 'business-type/:id/edit', element: <BusinessTypeCreate /> },
        { path: 'merchant-config', element: <MerchantConfig /> },
        { path: 'merchant-config/new', element: <MerchantConfigCreate /> },
        { path: 'merchant-config/:id/edit', element: <MerchantConfigCreate /> },
        { path: 'merchant-roles', element: <MerchantRoles /> },
        { path: 'merchant-roles/new', element: <MerchantRoleCreate /> },
        { path: 'merchant-roles/:id/edit', element: <MerchantRoleCreate /> },
        { path: 'merchants', element: <Merchants /> },
        { path: 'profile/:id/:tabname', element: <MerchantAccount /> },
        { path: 'profile/:id/store/new', element: <MerchantUserCreateStore /> },
        { path: 'profile/:id/store/edit', element: <MerchantUserCreateStore /> },
        { path: 'profile/:id/user/new', element: <MerchantUserCreateSystemUser /> },
        { path: 'profile/:id/user/edit', element: <MerchantUserCreateSystemUser /> },
        { path: 'merchants/new', element: <MerchantCreate /> },
        { path: 'merchants/:id/edit', element: <MerchantCreate /> },
        { path: 'merchant-stores', element: <MerchantStores /> },
        { path: 'merchant-stores/new', element: <MerchantStoreCreate /> },
        { path: 'merchant-stores/:id/edit', element: <MerchantStoreCreate /> },
        { path: 'merchant-document-types', element: <MerchantDocumentTypes /> },
        { path: 'merchant-document-types/new', element: <MerchantDocumentTypesCreate /> },
        { path: 'merchant-document-types/:id/edit', element: <MerchantDocumentTypesCreate /> },
      ],
    },
    {
      path: 'wallets',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'wallets', element: <Wallets /> },
        { path: 'wallets/wallet', element: <Wallet /> },
        { path: 'wallets/wallet/:id/edit', element: <Wallet /> },
        { path: 'central-wallets', element: <CentralWallets /> },
        { path: 'central-wallets/wallet', element: <CentralWallet /> },
        { path: 'central-wallets/wallet/:id/edit', element: <CentralWallet /> },
        { path: 'wallet-accounts', element: <WalletAccounts /> },
        { path: 'wallet-accounts/wallet', element: <WalletAccount /> },
      ],
    },
    {
      path: 'configuration',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'version', element: <AppVersions /> },
        { path: 'version/new', element: <AppVersionsCreate /> },
        { path: 'version/:id/edit', element: <AppVersionsCreate /> },
        {
          path: 'admin-emails',
          children: [
            { element: <AdminEmails />, index: true },
            // { path: 'admin-emails', element: <AdminEmails /> },
            { path: 'new', element: <AdminEmailCreate /> },
            { path: ':id/edit', element: <AdminEmailCreate /> },
          ],
        },
        {
          path: 'stb-staff',
          children: [
            { element: <StbStaff />, index: true },
            { path: 'new', element: <StbStaffCreateEdit /> },
            { path: ':id/edit', element: <StbStaffCreateEdit /> },
          ],
        },
        {
          path: 'offers',
          children: [
            { element: <Offers />, index: true },
            { path: 'new', element: <OfferCreateEdit /> },
            { path: ':id/edit', element: <OfferCreateEdit /> },
          ],
        },
        {
          path: 'stb-fees',
          children: [
            { element: <SlabList />, index: true },
            { path: ':userType/:transactionType', element: <SlabList /> },
            { path: ':id/history', element: <SlabHistory /> },
          ],
        },
        {
          path: 'demographics',
          children: [
            { element: <Countries />, index: true },
            { path: 'countries', element: <Countries /> },
            { path: 'countries/new', element: <CountriesCreate /> },
            { path: 'countries/:id/edit', element: <CountriesCreate /> },

            { path: 'provinces', element: <Provinces /> },
            { path: 'provinces/new', element: <ProvincesCreate /> },
            { path: 'provinces/:id/edit', element: <ProvincesCreate /> },

            { path: 'currencies', element: <Currencies /> },
            { path: 'currencies/new', element: <CurrenciesCreate /> },
            { path: 'currencies/:id/edit', element: <CurrenciesCreate /> },

            { path: 'languages', element: <Languages /> },
            { path: 'languages/new', element: <LanguagesCreate /> },
            { path: 'languages/:id/edit', element: <LanguagesCreate /> },

            { path: 'exchange-rates', element: <ExchangeRates /> },
            { path: 'exchange-rates/new', element: <ExchangeRatesCreate /> },
            { path: 'exchange-rates/:id/edit', element: <ExchangeRatesCreate /> },
          ],
        },

        { path: 'derogatory-words', element: <DerogatoryWords /> },
        { path: 'derogatory-words/new', element: <DerogatoryWordsCreate /> },
        { path: 'derogatory-words/:id/edit', element: <DerogatoryWordsCreate /> },

        { path: 'coins', element: <Coins /> },
        { path: 'coins/new', element: <CoinsCreate /> },
        { path: 'coins/:id/edit', element: <CoinsCreate /> },

        { path: 'app-configuration', element: <AppConfigurations /> },
        { path: 'app-configuration/new', element: <AppConfigurationsCreate /> },
        { path: 'app-configuration/:id/edit', element: <AppConfigurationsCreate /> },

        { path: 'xrpl-status-codes', element: <XrplStatusCodes /> },
        { path: 'xrpl-status-codes/new', element: <XrplStatusCodesCreate /> },
        { path: 'xrpl-status-codes/:id/edit', element: <XrplStatusCodesCreate /> },
        { path: 'accuant-error', element: <AccuantErrorList /> },
        { path: 'accuant-error/new', element: <AccuantErrorCreate /> },
        { path: 'accuant-error/:id/edit', element: <AccuantErrorCreate /> },
        { path: 'country-supported-coin', element: <CountrySupportedCoinList /> },
        { path: 'country-supported-coin/new', element: <CountrySupportedCoinCreate /> },
        { path: 'country-supported-coin/:id/edit', element: <CountrySupportedCoinCreate /> },
        { path: 'commissions', element: <CommissionsCreate /> },
      ],
    },
    {
      path: 'audit-trail',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: 'list', element: <AuditTrailList /> }],
    },
    {
      path: 'notification',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'list', element: <NotificationList /> },
        { path: 'new', element: <NotificationCreate /> },
        { path: ':id/edit', element: <NotificationCreate /> },
        { path: ':id/user-list', element: <NotificationUserList /> },
      ],
    },
    {
      path: 'reporting',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'master-transactions', element: <MasterTransactions /> },
        { path: 'revenue-report', element: <RevenueReportList /> },
        { path: 'revenue-component-break-report', element: <RevenueComponentBreakReportList /> },
        { path: 'p2p-transaction-volume', element: <P2pTransactions /> },
        { path: 'p2b-transaction-volume', element: <P2bTransactions /> },
        { path: 'deposit-transaction-volume', element: <Deposits /> },
        { path: 'withdraw-volume', element: <WithdrawlReport /> },
        { path: 'bill-payments-volume', element: <BillPayments /> },
        { path: 'balance-diffrence-report', element: <BalanceDiffrenceReportPage /> },
        // { path: 'new', element: <NotificationCreate /> },
        // { path: ':id/edit', element: <NotificationCreate /> },
      ],
    },
    {
      path: 'support',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'agents', element: <Agents /> },
        { path: 'agents/new', element: <AgentCreate /> },
        { path: 'agents/:id/edit', element: <AgentCreate /> },
        {
          path: 'merchant',
          children: [
            { element: <Agents />, index: true },
            { path: 'support-categories', element: <SupportCategory /> },
            { path: 'support-categories/new', element: <SupportCategoryCreate /> },
            { path: 'support-categories/:id/edit', element: <SupportCategoryCreate /> },

            { path: 'support-ticket/:type', element: <MerchantSupport /> },
            { path: 'support-ticket/:type/:conversationKey', element: <MerchantSupport /> },

            { path: 'merchant-faq', element: <MerchantFAQ /> },
            { path: 'merchant-faq/new', element: <MerchantFAQCreate /> },
            { path: 'merchant-faq/:id/edit', element: <MerchantFAQCreate /> },

            { path: 'priority', element: <Priority /> },
            { path: 'priority/new', element: <PriorityCreate /> },
            { path: 'priority/:id/edit', element: <PriorityCreate /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Agents />, index: true },
            { path: 'support-ticket/:type', element: <UserSupport /> },
            { path: 'support-ticket/:type/:conversationKey', element: <UserSupport /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const SetupPassword = Loadable(lazy(() => import('../pages/auth/setupPassword')));
const TwoFactorAuthentication = Loadable(lazy(() => import('../pages/auth/TwoFactorAuthentication')));
const VerifyTwoFactorAuthentication = Loadable(lazy(() => import('../pages/auth/VerifyTwoFactorAuthentication')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/users/UserProfile')));
const ManageProfile = Loadable(lazy(() => import('../pages/dashboard/users/manageProfile/manageProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/users/UserCards')));

const RetailUsers = Loadable(lazy(() => import('../pages/dashboard/users/retialUsers/RetailUsers')));
const MerchantUsers = Loadable(lazy(() => import('../pages/dashboard/users/merchantUsers/MerchantUsers')));

const PasswordChangeTable = Loadable(
  lazy(() => import('../pages/dashboard/users/passwordChangeTable/PasswordChangeTable'))
);
const RetailKYCTable = Loadable(lazy(() => import('../pages/dashboard/users/retailKYC/RetailKYCTable')));
const RetailKYCCreate = Loadable(lazy(() => import('../pages/dashboard/users/retailKYC/RetailKYCCreate')));

// const MerchantKYBList = Loadable(lazy(() => import('../pages/dashboard/users/merchantKYB/merchantKYBList')));
const MerchantKYBCreate = Loadable(lazy(() => import('../pages/dashboard/users/merchantKYB/merchantKYBCreate')));

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/users/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/users/UserCreate')));
const MerchantUserCreate = Loadable(lazy(() => import('../pages/dashboard/users/MerchantCreate')));

// TRANSACTIONS
const BitgoTransactions = Loadable(lazy(() => import('../pages/dashboard/transactions/bitgo/BitgoTransactions')));
const BitgoTransaction = Loadable(lazy(() => import('../pages/dashboard/transactions/bitgo/BitgoTransaction')));
const DepositTransaction = Loadable(
  lazy(() => import('../pages/dashboard/transactions/apayloTransaction/deposit/depositTransaction'))
);
const WithdrawTransaction = Loadable(
  lazy(() => import('../pages/dashboard/transactions/apayloTransaction/withdraw/withdrawTransaction'))
);

const WalletTransactions = Loadable(lazy(() => import('../pages/dashboard/transactions/wallet/WalletTransactions')));
const WalletTransaction = Loadable(lazy(() => import('../pages/dashboard/transactions/wallet/WalletTransaction')));
const WalletTransactionCreate = Loadable(
  lazy(() => import('../pages/dashboard/transactions/wallet/WalletTransactionCreate'))
);

const FundingQueue = Loadable(lazy(() => import('../pages/dashboard/transactions/fundingQueue/FundingQueue')));
const FundingQueueCreate = Loadable(
  lazy(() => import('../pages/dashboard/transactions/fundingQueue/FundingQueueCreate'))
);
const EftAccountsPage = Loadable(lazy(() => import('../pages/dashboard/transactions/eftAccounts/EftAccountsList')));
const RetailDeposit = Loadable(lazy(() => import('../pages/dashboard/transactions/deposit/retailUser/retailDeposits')));
const RetailWalletTransaction = Loadable(
  lazy(() => import('../pages/dashboard/transactions/walletTransaction/retailUser/retailWalletTransaction'))
);
const MerchantWalletTransaction = Loadable(
  lazy(() => import('../pages/dashboard/transactions/walletTransaction/merchantUser/merchantWalletTransaction'))
);
const DepositEdit = Loadable(lazy(() => import('../pages/dashboard/transactions/deposit/depositEdit')));
const WithdrawEdit = Loadable(lazy(() => import('../pages/dashboard/transactions/withdraw/withdrawEdit')));
const RetailWithdraw = Loadable(
  lazy(() => import('../pages/dashboard/transactions/withdraw/retailUser/retailWithdrawlReport'))
);
const MerchantWithdraw = Loadable(
  lazy(() => import('../pages/dashboard/transactions/withdraw/merchantUser/merchantWithdrawlReport'))
);

// WALLETS
const Wallets = Loadable(lazy(() => import('../pages/dashboard/wallets/wallets/Wallets')));
const Wallet = Loadable(lazy(() => import('../pages/dashboard/wallets/wallets/wallet')));
const CentralWallets = Loadable(lazy(() => import('../pages/dashboard/wallets/centralWallets/CentralWallets')));
const CentralWallet = Loadable(lazy(() => import('../pages/dashboard/wallets/centralWallets/CentralWallet')));
const WalletAccounts = Loadable(lazy(() => import('../pages/dashboard/wallets/walletAccounts/WalletAccounts')));
const WalletAccount = Loadable(lazy(() => import('../pages/dashboard/wallets/walletAccounts/WalletAccount')));

// CONFIGURATION
const Countries = Loadable(lazy(() => import('../pages/dashboard/configurations/demographics/countries/Countries')));
const Offers = Loadable(lazy(() => import('../pages/dashboard/configurations/offers/offers')));
const CountriesCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/demographics/countries/CountryCreate'))
);
const Provinces = Loadable(lazy(() => import('../pages/dashboard/configurations/demographics/provinces/Provinces')));
const ProvincesCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/demographics/provinces/ProvincesCreate'))
);

const Currencies = Loadable(lazy(() => import('../pages/dashboard/configurations/demographics/currencies/Currencies')));
const CurrenciesCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/demographics/currencies/CurrencyCreate'))
);

const Languages = Loadable(lazy(() => import('../pages/dashboard/configurations/demographics/languages/Languages')));
const LanguagesCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/demographics/languages/LanguagesCreate'))
);

const ExchangeRates = Loadable(
  lazy(() => import('../pages/dashboard/configurations/demographics/exchnageRates/ExchangeRates'))
);
const ExchangeRatesCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/demographics/exchnageRates/ExchangeRatesCreate'))
);

const AccuantErrorList = Loadable(
  lazy(() => import('../pages/dashboard/configurations/AccuantError/AccuantErrorList'))
);
const AccuantErrorCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/AccuantError/AccuantErrorCreate'))
);

const CommissionsCreate = Loadable(lazy(() => import('../pages/dashboard/configurations/commissions/CommissionsForm')));

const CountrySupportedCoinList = Loadable(
  lazy(() => import('../pages/dashboard/configurations/countrySupportedCoin/CountrySupportedCoin'))
);

const CountrySupportedCoinCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/countrySupportedCoin/CountrySupportCoinCreate'))
);

// APP

const AppVersions = Loadable(lazy(() => import('../pages/dashboard/configurations/appVersions/AppVersions')));
const AppVersionsCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/appVersions/AppVersionsCreate'))
);

// Admin Email
const AdminEmails = Loadable(lazy(() => import('../pages/dashboard/configurations/adminEmails/AdminEmails')));
const AdminEmailCreate = Loadable(lazy(() => import('../pages/dashboard/configurations/adminEmails/AdminEmailCreate')));

// STB Staff
const StbStaff = Loadable(lazy(() => import('../pages/dashboard/configurations/stbStaff/stbStaff')));
const StbStaffCreateEdit = Loadable(
  lazy(() => import('../pages/dashboard/configurations/stbStaff/stbStaffCreateEdit'))
);

// STB Fees
const FeeList = Loadable(lazy(() => import('../pages/dashboard/configurations/feeList/feeList')));
const SlabList = Loadable(lazy(() => import('../pages/dashboard/configurations/feeList/slabList/slabList')));
const SlabHistory = Loadable(lazy(() => import('../pages/dashboard/configurations/feeList/slabList/slabHistory')));

const DerogatoryWords = Loadable(
  lazy(() => import('../pages/dashboard/configurations/derogatorywords/DerogatoryWords'))
);
const DerogatoryWordsCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/derogatorywords/DerogatoryWordsCreate'))
);

const XrplStatusCodes = Loadable(
  lazy(() => import('../pages/dashboard/configurations/xrplStatusCodes/XrplStatusCodes'))
);
const XrplStatusCodesCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/xrplStatusCodes/XrplStatusCodesCreate'))
);

const Coins = Loadable(lazy(() => import('../pages/dashboard/configurations/coins/Coins')));
const CoinsCreate = Loadable(lazy(() => import('../pages/dashboard/configurations/coins/CoinsCreate')));

const AppConfigurations = Loadable(
  lazy(() => import('../pages/dashboard/configurations/appConfigurations/AppConfigurations'))
);
const AppConfigurationsCreate = Loadable(
  lazy(() => import('../pages/dashboard/configurations/appConfigurations/AppConfigurationsCreate'))
);

// MERCHANTS
const BusinessCategory = Loadable(
  lazy(() => import('../pages/dashboard/merchants/BusinessCategory/BusinessCategoryList'))
);
const BusinessCategoryCreate = Loadable(
  lazy(() => import('../pages/dashboard/merchants/BusinessCategory/BusinessCategoryCreate'))
);
const BusinessType = Loadable(lazy(() => import('../pages/dashboard/merchants/BusinessType/BusinessTypeList')));
const BusinessTypeCreate = Loadable(lazy(() => import('../pages/dashboard/merchants/BusinessType/BusinessTypeCreate')));
const MerchantConfig = Loadable(lazy(() => import('../pages/dashboard/merchants/MerchantConfig/MerchantConfigList')));
const MerchantConfigCreate = Loadable(
  lazy(() => import('../pages/dashboard/merchants/MerchantConfig/MerchantConfigCreate'))
);
const MerchantRoles = Loadable(lazy(() => import('../pages/dashboard/merchants/MerchantRoles/MerchantRolesList')));
const MerchantRoleCreate = Loadable(
  lazy(() => import('../pages/dashboard/merchants/MerchantRoles/MerchantRoleCreate'))
);
const Merchants = Loadable(lazy(() => import('../pages/dashboard/merchants/MerchantsList/MerchantsList')));
const MerchantAccount = Loadable(lazy(() => import('../pages/dashboard/merchants/MerchantsList/MerchantAccount')));
const MerchantCreate = Loadable(lazy(() => import('../pages/dashboard/merchants/MerchantsList/MerchantCreate')));
const MerchantStores = Loadable(lazy(() => import('../pages/dashboard/merchants/MerchantsStores/MerchantsStoreList')));
const MerchantStoreCreate = Loadable(
  lazy(() => import('../pages/dashboard/merchants/MerchantsStores/MerchantStoreCreate'))
);
const MerchantDocumentTypes = Loadable(
  lazy(() => import('../pages/dashboard/merchants/DocumentTypes/DocumentTypesList'))
);
const MerchantDocumentTypesCreate = Loadable(
  lazy(() => import('../pages/dashboard/merchants/DocumentTypes/DocumentTypeCreate'))
);

const AuditTrailList = Loadable(lazy(() => import('../pages/dashboard/auditTrail/auditTrailList')));
const NotificationList = Loadable(lazy(() => import('../pages/dashboard/notification/NotifictionList')));
const NotificationCreate = Loadable(lazy(() => import('../pages/dashboard/notification/NotificationCreate')));
const NotificationUserList = Loadable(lazy(() => import('../pages/dashboard/notification/NotificationUserList')));

const RevenueReportList = Loadable(lazy(() => import('../pages/dashboard/Reporting/revenueReporting/RevenueReport')));
const RevenueComponentBreakReportList = Loadable(
  lazy(() => import('../pages/dashboard/Reporting/revenueComponentBreak/RevenueComponentBreakReport'))
);
const P2pTransactionVolumePage = Loadable(
  lazy(() => import('../pages/dashboard/Reporting/transactionVolume/P2pTransactionVolume'))
);
const P2bTransactionVolumePage = Loadable(
  lazy(() => import('../pages/dashboard/Reporting/transactionVolume/P2bTransactionVolume'))
);
const DepositTransactionVolumePage = Loadable(
  lazy(() => import('../pages/dashboard/Reporting/transactionVolume/DepositTransactionVolume'))
);
const WithdrawVolumePage = Loadable(
  lazy(() => import('../pages/dashboard/Reporting/transactionVolume/WithdrawVolume'))
);
const BillPaymentsVolumePage = Loadable(
  lazy(() => import('../pages/dashboard/Reporting/transactionVolume/BillPaymentsVolume'))
);
const BalanceDiffrenceReportPage = Loadable(
  lazy(() => import('../pages/dashboard/Reporting/transactionVolume/BalanceDiffrenceReport'))
);

// APP
const MerchantSupport = Loadable(lazy(() => import('../pages/dashboard/support/merchant/supporttickets/Support')));
const UserSupport = Loadable(lazy(() => import('../pages/dashboard/support/user/supporttickets/Support')));
const Agents = Loadable(lazy(() => import('../pages/dashboard/support/agents/Agents')));
const AgentCreate = Loadable(lazy(() => import('../pages/dashboard/support/agents/AgentCreate')));

const SupportCategory = Loadable(
  lazy(() => import('../pages/dashboard/support/merchant/supportcategory/SupportCategory'))
);
const SupportCategoryCreate = Loadable(
  lazy(() => import('../pages/dashboard/support/merchant/supportcategory/SupportCategoryCreate'))
);

const Priority = Loadable(lazy(() => import('../pages/dashboard/support/merchant/priority/Priority')));
const PriorityCreate = Loadable(lazy(() => import('../pages/dashboard/support/merchant/priority/PriorityCreate')));

const MerchantFAQ = Loadable(lazy(() => import('../pages/dashboard/support/merchant/merchantfaq/MerchantFAQ')));
const MerchantFAQCreate = Loadable(
  lazy(() => import('../pages/dashboard/support/merchant/merchantfaq/MerchantFAQCreate'))
);

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

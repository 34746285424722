import React, { useState, useEffect } from 'react';
import { Box, Card, FormControlLabel, Switch, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import Scrollbar from '../../../../components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSkeleton } from '../../../../components/table';
import useTable, { emptyRows } from '../../../../hooks/useTable';
import { adminApi } from '../../../../utils/axios';
import { ADMIN_DEPOSIT_REPORT } from '../../../../utils/apiUrl';
import { fCustomDate } from '../../../../utils/formatTime';
import DepositsFilter from './depositsFilter';
import DepositsHeaders from './depositsHeader';
import DepositsTableRow from './depositsTableRow';
import useEarnings from '../../../../hooks/useEarnings';

const TABLE_HEAD = [
  { id: 'created_date', label: 'Date', align: 'left' },
  { id: 'reference_number', label: 'Tran ID', align: 'left' },
  { id: 'sender', label: 'From', align: 'left' },
  { id: 'send_amount', label: 'Transaction Value', align: 'left' },
  { id: 'cashback', label: 'Cashback', align: 'left' },
  { id: 'stb_fee', label: 'STB Transaction Fee', align: 'left' },
  { id: 'stb_earning', label: 'STB Earning', align: 'left' },
  { id: 'stb_expense', label: 'STB Expense', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
];

const DepositsData = () => {
  // Getting Current Date
  const currentDate = new Date();

  // Getting Date which is 30 Days back from current date
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const { dense, page, order, orderBy, rowsPerPage, setPage, onChangeDense, onChangePage, onChangeRowsPerPage, onSort } =
    useTable();

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState({ count: 0, data: [] });
  const [filterParameters, setFilterParameters] = useState({
    search: '',
    status: 'processed',
    from_date: fCustomDate(new Date(thirtyDaysAgo)),
    to_date: fCustomDate(new Date(currentDate)),
  });
  const [totalData, setTotalData] = useState(null);

  // Using Custom Hook to get net earning
  const { netEarning, isProfitLoss } = useEarnings(totalData);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getDepositsReport();
  }, [rowsPerPage, page, filterParameters, order, orderBy]);

  // Getting Deposits Data
  const getDepositsReport = async () => {
    // console.log('filter parameters', filterParameters);
    setIsLoading(true);
    try {
      const response = await adminApi.get(ADMIN_DEPOSIT_REPORT, {
        params: {
          offset: rowsPerPage * page,
          limit: rowsPerPage,
          ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
          ...filterParameters,
        },
      });
      console.log(response, 'response');
      setIsLoading(false);
      setTableData(response?.data);
      setTotalData({ ...response?.data.totals, total_transactions: response?.data.count });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error?.message, { variant: 'error' });
      setTableData(error);
    }
  };

  // If there is no data
  const isNotFound = tableData?.results?.length === 0;

  // Check Dense Height
  const denseHeight = dense ? 52 : 72;

  return (
    <Card sx={{ margin: '40px 0px' }}>
      {/* Deposits Report Filter */}
      <DepositsFilter
        filterParameters={filterParameters}
        onFilterChange={(val) => {
          setFilterParameters(val);
          setPage(0);
        }}
      />

      {/* Deposits Report Headers */}
      {totalData && <DepositsHeaders netEarning={netEarning} totalData={totalData} isProfitLoss={isProfitLoss} />}

      {/* Deposits Report Data Table */}
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Scrollbar>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} rowCount={tableData.count} onSort={onSort}/>
            {isLoading ? (
              <>
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
                <TableSkeleton />
              </>
            ) : (
              <TableBody>
                {tableData?.results?.map((row) => (
                  <DepositsTableRow key={row.id} row={row} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.count)} />
                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            )}
          </Table>
        </Scrollbar>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={tableData.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />

        <FormControlLabel
          control={<Switch checked={dense} onChange={onChangeDense} />}
          label="Dense"
          sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
        />
      </Box>
    </Card>
  );
};

export default DepositsData;

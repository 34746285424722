import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSkeleton } from '../../../../components/table';
import useTable, { emptyRows } from '../../../../hooks/useTable';
import { adminApi } from '../../../../utils/axios';
import { MERCHANT_KYB, MERCHANT_KYB_HIGHLIGHTS, USER_KYC, USER_KYC_HIGHLIGHTS } from '../../../../utils/apiUrl';
import { fCustomDate } from '../../../../utils/formatTime';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { PATH_AFTER_LOGIN } from '../../../../config';
import { PATH_ACCOUNT } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import MerchantKybFilter from './merchantKybFilter';
import MerchantKybListTableRow from './merchantKybListTableRow';
import MerchantKybHeaders from './merchantKybHeaders';

const TABLE_HEAD = [
  { id: 'created_date', label: 'Date/Time', align: 'left' },
  { id: 'id', label: 'ID', align: 'left' },
  { id: 'name', label: 'Merchant Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'status', label: 'Merchant Status', align: 'center' },
  { id: 'modified_date', label: 'Date Updated', align: 'left' },
  { id: '', label: '', align: 'left' },
];

const MerchantKybTableList = () => {
  // Getting Current Date
  const currentDate = new Date();

  // Getting Date which is 30 Days back from current date
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = useTable();

  const { themeStretch } = useSettings();

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState({ count: 0, data: [] });
  const [filterParameters, setFilterParameters] = useState({
    search: '',
    status: '',
    from_date: fCustomDate(new Date(thirtyDaysAgo)),
    to_date: fCustomDate(new Date(currentDate)),
  });
  const [totalData, setTotalData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getMerchantKybListData();
  }, [rowsPerPage, page, filterParameters, order, orderBy]);

  useEffect(() => {
    getKybHighlightsData();
  }, []);

  // Getting Bill Payments Data
  const getMerchantKybListData = async () => {
    setIsLoading(true);
    try {
      const response = await adminApi.get(MERCHANT_KYB, {
        params: {
          offset: rowsPerPage * page,
          limit: rowsPerPage,
          ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
          ...filterParameters,
        },
      });
      console.log(response, 'response');
      setIsLoading(false);
      setTableData(response?.data);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error?.message, { variant: 'error' });
      setTableData(error);
    }
  };

  // Getting KYC Hightlights Data
  const getKybHighlightsData = async () => {
    try {
      const response = await adminApi.get(MERCHANT_KYB_HIGHLIGHTS);
      setTotalData(response?.data?.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  // If there is no data
  const isNotFound = tableData?.results?.length === 0;

  // Check Dense Height
  const denseHeight = dense ? 52 : 72;

  return (
    <Page title="Merchant KYB: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Merchant KYB"
          links={[{ name: 'Accounts', href: PATH_AFTER_LOGIN }, { name: 'List' }]}
          // action={
          //   <Button
          //     variant="contained"
          //     component={RouterLink}
          //     to={PATH_ACCOUNT.merchantKybTable.new}
          //     startIcon={<Iconify icon={'eva:plus-fill'} />}
          //   >
          //     New KYB
          //   </Button>
          // }
        />
        <Card sx={{ margin: '40px 0px' }}>
          {/* Merchant KYB Filter */}
          <MerchantKybFilter
            filterParameters={filterParameters}
            onFilterChange={(val) => {
              setFilterParameters(val);
              setPage(0);
            }}
          />

          {/* Merchant KYB Headers */}
          {totalData && <MerchantKybHeaders totalData={totalData} />}

          {/* Merchant KYB Data Table */}
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.count}
                  onSort={onSort}
                />
                {isLoading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.results?.map((row) => (
                      <MerchantKybListTableRow key={row.id} row={row} />
                    ))}
                    <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.count)} />
                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </Scrollbar>
          </TableContainer>
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={tableData.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default MerchantKybTableList;

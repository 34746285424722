import { useNavigate } from 'react-router';
import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import _ from 'lodash';
import { fDate } from '../../../../utils/formatTime';
import Iconify from '../../../../components/Iconify';
import { PATH_ACCOUNT } from '../../../../routes/paths';

const docType = [
  { code: '1', label: 'Passport', value: 'PP' },
  { code: '2', label: 'Drivers Licence', value: 'DL' },
  { code: '3', label: 'Govt Issued ID Card', value: 'ID' },
  { code: '4', label: 'Utility Bill', value: 'UB' },
  { code: '5', label: 'Recidence Permit', value: 'RP' },
];

const RetailKycListTableRow = ({ row }) => {
  const { id, application_id, created_date, modified_date, user, status, document_type } = row;

  const kycDocument = _.find(docType, { value: document_type });

  const navigate = useNavigate();

  return (
    <TableRow key={id}>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {fDate(created_date)}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {application_id}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        {user?.first_name} {user?.last_name}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        {user?.phone_number}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        {user?.email}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
        {document_type?.replace(/_/g, ' ')}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        <Iconify
          icon={status === 'complete' ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
          sx={{
            width: 20,
            height: 20,
            color: 'success.main',
            ...(!status && { color: 'warning.main' }),
          }}
        />
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {fDate(modified_date)}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        <Typography
          sx={{ color: '#FFBE00', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => navigate(PATH_ACCOUNT.retailUsers.profile(user?.id, 'personal-info'))}
        >
          View
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default RetailKycListTableRow;
